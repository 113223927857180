import { useState } from "react";
import Header from "../../Components/Header";
import "./style.css";
import { useNavigate } from "react-router-dom";
import { routes } from "../../routes";
import Bounce from "react-reveal/Bounce";
import Zoom from "react-reveal/Zoom";
import times from "./time.svg";
export default function Services() {
  const [service, openService] = useState(0);
  const Navigate = useNavigate();

  return (
    <div className="services">
      <Header />{" "}
      <Zoom top>
        <h3 className="zag">Экспресс-проверка</h3>
      </Zoom>
      <Zoom bottom>
        <p className="pred">товарного знака</p>{" "}
      </Zoom>
      <div className="etpas">Этапы регистрации</div>
      <Bounce left>
        {" "}
        <div className="serviceItem">
          <div className="serviceItemHeader" onClick={() => openService(0)}>
            <p>
              <div>Шаг первый: </div>
              <span>экспресс-проверка</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 0 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li className="title">
                <span>90 минут</span> <img src={times} alt="" />
              </li>
              <li>Получаем информацию о товарном знаке и продуктах/услугах</li>
              <li>
                Проверяем на тождество по зарегистрированным товарными знаками
              </li>
              <li>Полный поиск</li>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(0)}
            style={service == 0 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
      <Bounce right>
        {" "}
        <div className="serviceItem">
          <div className="serviceItemHeader" onClick={() => openService(1)}>
            <p>
              <div>Шаг второй: </div>
              <span>подача заявки и закрепление приоритета</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 1 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li className="title">
                <span>От 8 часов</span> <img src={times} alt="" />
              </li>
              <li>
                Подбираем классы МКТУ в соответствии со стратегией вашего
                бизнеса
              </li>
              <li>Подаём заявку в Роспатент</li>
              <li>
                Отправляем уведомление о приёмке и регистрации заявки (форма
                940)
              </li>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(1)}
            style={service == 1 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
      <Bounce left>
        {" "}
        <div className="serviceItem">
          <div className="serviceItemHeader" onClick={() => openService(2)}>
            <p>
              <div>Шаг третий: </div>
              <span>экспертиза в Роспатенте</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 2 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li className="title">
                <span>От 3,5 месяцев</span> <img src={times} alt="" />
              </li>
              <li>Мы ведём делопроизводство</li>
              <li>Отвечаем на вопросы Роспатента</li>
              <li>Своевременно информируем Вас о ходе регистрации</li>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(2)}
            style={service == 2 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
      <Bounce right>
        {" "}
        <div className="serviceItem">
          <div className="serviceItemHeader" onClick={() => openService(3)}>
            <p>
              <div>Шаг четвёртый: </div>
              <span>Успешная регистрация и получение свидетельства</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 3 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li className="title">
                <span>От 10 дней</span> <img src={times} alt="" />
              </li>
              <li>Мы ведём делопроизводство</li>
              <li>Своевременно информируем Вас о ходе регистрации</li>
              <li>Отвечаем на вопросы Роспатента</li>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(3)}
            style={service == 3 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
      <Zoom top>
        <h3 className="zag" style={{ marginTop: "10vw" }}>
          Стоимость <br />
          регистрации
        </h3>
      </Zoom>
      <Zoom bottom>
        <p className="pred">товарного знака</p>{" "}
      </Zoom>
      <Bounce left>
        {" "}
        <div className="serviceItem serviceS serviceS1">
          <div className="serviceItemHeader" onClick={() => openService(4)}>
            <p>
              <div>Тариф 1 </div>
              <span>конструктор</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 4 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li>Подача заявки</li>
              <li>Экспертиза в Роспатенте</li>
              <li>Получение свидетельства</li>
              <div>
                <div className="price">11 500 р</div>
                <span className="dokup">Можно докупить необходимые услуги</span>
                <div
                  className="sendButton"
                  onClick={() => Navigate(routes.form)}
                >
                  Заказать
                </div>
              </div>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(4)}
            style={service == 4 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
      <Bounce left>
        {" "}
        <div className="serviceItem serviceS serviceS2">
          <div className="serviceItemHeader" onClick={() => openService(5)}>
            <p>
              <div>Тариф 2 </div>
              <span>под ключ</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 5 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li>Экспресс-проверка</li>
              <li>Полный поиск</li>
              <li>Подача заявки</li>
              <li>Экспертиза в Роспатенте</li>
              <li>Получение свидетельства</li>
              <div>
                <div className="price">22 500 р</div>

                <div
                  className="sendButton"
                  onClick={() => Navigate(routes.form)}
                >
                  Заказать
                </div>
              </div>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(5)}
            style={service == 5 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
      <Bounce left>
        {" "}
        <div className="serviceItem serviceS serviceS3">
          <div className="serviceItemHeader" onClick={() => openService(6)}>
            <p>
              <div>Тариф 3 </div>
              <span>с гаратнией</span>
            </p>
          </div>
          <Zoom left duration={400}>
            <ul
              className="serviceUl"
              style={
                service != 6 ? { display: "none" } : { transform: "scaleY(1)" }
              }
            >
              <li>Экспресс-проверка</li>
              <li>Полный поиск</li>
              <li>Подача заявки</li>
              <li>Экспертиза в Роспатенте</li>
              <li>Получение свидетельства</li>
              <li>Возврат средств при отказе Роспатента</li>
              <div>
                <div className="price">35 000 р</div>

                <div
                  className="sendButton"
                  onClick={() => Navigate(routes.form)}
                >
                  Заказать
                </div>
              </div>
            </ul>
          </Zoom>
          <div
            className="spisok"
            onClick={() => openService(6)}
            style={service == 6 ? { display: "none" } : {}}
          >
            <p>Раскрыть список</p>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="15"
              height="15"
              viewBox="0 0 15 15"
              fill="none"
            >
              <circle cx="7.5" cy="7.5" r="7.5" fill="#D9D9D9" />
              <circle
                cx="7.5"
                cy="7.5"
                r="7.5"
                fill="url(#paint0_linear_444_39)"
              />
              <path
                d="M3.94727 6.31592L7.20384 9.47381L11.0525 6.31592"
                stroke="white"
                stroke-width="1.5"
              />
              <defs>
                <linearGradient
                  id="paint0_linear_444_39"
                  x1="7.5"
                  y1="0"
                  x2="7.5"
                  y2="15"
                  gradientUnits="userSpaceOnUse"
                >
                  <stop offset="0.322917" stop-color="#0068FF" />
                  <stop offset="1" stop-color="#0068FF" stop-opacity="0.69" />
                </linearGradient>
              </defs>
            </svg>
          </div>
        </div>
      </Bounce>
    </div>
  );
}
